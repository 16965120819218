import React from 'react'

export const Hero = () => {
  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 fw-600 text-grayscale--500'>SCR - Sistema de Informação de Crédito</h2>
            <p className='fs-12 fw-400 text-grayscale--400 mt-md-5 mt-xl-2'>
              O SCR (Sistema de Informações de Crédito) é um importante instrumento que vem sendo utilizado e aperfeiçoado desde 1997 pelo Bacen (Banco Central do Brasil) para supervisionar a evolução e a qualidade das carteiras de empréstimos e financiamentos dos integrantes do SFN (Sistema Financeiro Nacional).
            </p>
            <p className='fs-12 fw-400 text-grayscale--400'>
              Além disso, o Bacen disponibiliza aos integrantes do SFN informações referentes ao histórico das operações de empréstimos e financiamentos dos últimos 13 meses das pessoas físicas e jurídicas, subsidiando na decisão de crédito e de negócios.
            </p>
            <p className='fs-12 fw-400 text-grayscale--400'>
              É importante que nossos clientes saibam:
            </p>
            <ul className='fs-12 fw-400 text-grayscale--400 '>
              <li>Os integrantes do SFN devem obter autorização prévia do cliente/proponente para acessar as suas informações no SCR.</li>
              <li>As informações apresentadas no SCR referem-se ao saldo contábil das operações de crédito no último dia de cada mês.</li>
              <li>Eventuais solicitações de correções, exclusões, manifestações de discordância e registro de medidas judiciais quanto às informações constantes do SCR deverão ser dirigidas ao integrante do SFN que encaminhou a operação ao SCR, por meio de requerimento escrito e fundamentado e, quando for o caso, acompanhado da decisão judicial.</li>
            </ul>
            <p className='fs-12 fw-400 text-grayscale--400'>
              Esclarecimentos adicionais ou consulta às informações em seu nome no SCR, poderão ser obtidos no Bacen, em uma de suas unidades, na central de atendimento ao público (0800 979 2345) ou no site <a target='_blank' title='Acesse www.bcb.gov.br' href='https://www.bcb.gov.br' rel='noreferrer'>www.bcb.gov.br</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
